<template>
  <ion-page>
    <ion-content
      style="--background: var(--ion-color-light); align-items: center"
    >
      <div id="videoSDK" style="height: 100%; background: #e5e5e5;display:none;padding-top: 76px"></div>
      <div id="content">
        <radial-progress-bar
          :diameter="diameter"
          :completed-steps="completedSteps"
          :total-steps="totalSteps"
          :start-color="startColor"
          :stop-color="stopColor"
          :inner-stroke-color="innerStrokeColor"
        >
          <div id="circle">
            <span style="text-align: center; width: 100%">{{ initials }}</span>
          </div>
        </radial-progress-bar>

        <ion-card class="main-card" style="padding-top: 104px">
          <ion-card-header>
            <ion-card-title>Hi {{ user.first_name }},</ion-card-title>
            <p style="margin-bottom: 10px">
              your consult will start in the next few minutes.
            </p>
            <router-link
              :to="{ path: '/member/vitals' }"
              class="router-button unselected"
              >Update your vitals while you wait.</router-link
            >
            <p style="font-size: 10px;padding-top: 10px;">To return to the waiting room please <br> click the WeDoc button at the bottom of your screen</p>
             <p>Available times Mon-Fri {{tradingStart}}-{{tradingEnd}}</p>
          </ion-card-header>

          <ion-card-content>
            <img src="/assets/icon/clock.svg" height="150" class="clock" />

            <p style="margin-bottom: 5px"><strong>Don't want to wait?</strong></p>

            <a class="router-button" href="" @click.prevent="cancelConsult()"
              >Cancel Consult</a
            >

          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonContent,
  IonPage,
} from "@ionic/vue";
import { defineComponent } from "vue";
import RadialProgressBar from "vue-radial-progress";

import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
const meeting = new VideoSDKMeeting();

export default defineComponent({
  name: "Waiting Room",
  components: {
    IonPage,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonContent,
    RadialProgressBar,
  },
  data() {
    return {
      payload: {
        id: null,
        selected: null,
        user_id: null,
      },
      completedSteps: 20,
      totalSteps: 100,
      diameter: 178,
      strokeLinecap: "round",
      startColor: "#BF0000",
      stopColor: "#BF0000",
      innerStrokeColor: "#D1D1D1",
    };
  },
  watch: {
    meeting: function(){
      console.log(this.meeting.meeting_id);
      if(this.meeting.meeting_id){
        document.getElementById('videoSDK').style.display = "block";
        document.getElementById('content').style.display = "none";
        meeting.init({
        name: this.user.first_name + " " + this.user.last_name,
        apiKey: "3cec4a30-52a7-4ea2-8e02-1c1603b414fb", // generated from app.videosdk.live
        meetingId: this.meeting.meeting_id,

        containerId: "videoSDK",

        micEnabled: true,
        webcamEnabled: true,
        participantCanToggleSelfWebcam: true,
        participantCanToggleSelfMic: true,

        chatEnabled: true,
        screenShareEnabled: false,
        pollEnabled: false,
        whiteBoardEnabled: false,
        raiseHandEnabled: true,

        recordingEnabled: false,

        brandingEnabled: true,
        brandLogoURL:
          "http://localhost:8100/assets/icon/WeDoc_Logo_Standalone_Landscape.svg",
        brandName: "WeDoc",
        poweredBy: false,

        participantCanLeave: false, // if false, leave button won't be visible

        livestream: {
          autoStart: false,
        },

        permissions: {
          askToJoin: false, // Ask joined participants for entry in meeting
          toggleParticipantMic: false, // Can toggle other participant's mic
          toggleParticipantWebcam: false, // Can toggle other participant's webcam
        },

        joinScreen: {
          visible: true, // Show the join screen ?
          title: "Start Video Consult",
        },
      });
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    initials() {
      return this.user.first_name.charAt(0) + this.user.last_name.charAt(0);
    },
    consult() {
      return this.$store.state.consults.current_consult;
    },
    meeting() {
      return this.$store.state.consults.meeting;
    },
    tradingHourData() {
      return this.$store.state.admin.tradingHours;
    },
    tradingStart() {
      if(this.tradingHourData.start_time){
        let start = this.tradingHourData.start_time.split(":");

        var ampm = "am";
        if( start[0] > 12 ) {
            start[0] -= 12;
            ampm = "pm";
        }

        return start[0]+":"+start[1]+ampm;
      }
      return "";
    },
    tradingEnd() {
      if(this.tradingHourData.end_time){
        let end = this.tradingHourData.end_time.split(":");

        var ampm = "am";
        if( end[0] > 12 ) {
            end[0] -= 12;
            ampm = "pm";
        }

        return end[0]+":"+end[1]+ampm;
      }
      return "";
    },
  },
  ionViewDidEnter() {
    this.payload.user_id = this.user.id;
  },
  methods: {
    cancelConsult: function () {
      console.log('test', this.user.id);
      this.$store.dispatch("CancelConsult", this.user.id);
    },
    meetingStarted: function () {
      this.$store.dispatch("GetMeeting", this.user.id);
    },
  },
  mounted() {
    window.Echo.channel(this.user.id);
    window.Echo.connector.socket.on("App\\Events\\MeetingStarted", (data) => {
      console.log(data);
      this.meetingStarted();
    });

    window.Echo.channel(this.user.id);
    window.Echo.connector.socket.on(
      "App\\Events\\SendMedicalCertificate",
      (data) => {
        console.log(data);
        this.$store.dispatch("GetConsult", this.user.id);
        console.log(this.$store.state.consults.consult.script);
        this.$router.push("/member/consult/medicalcertificate");
      }
    );
  },
});
</script>

<style lang="css" scoped>
#circle {
  width: 140px;
  height: 140px;
  background: var(--ion-color-tertiary);
  border-radius: 50%;
  color: var(--ion-color-primary);
  display: flex;
  align-items: center;
  font-size: 50px;
}

.radial-progress-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  top: 73px;
}

.radial-progress-inner {
  width: 125px;
  height: 150px;
}

.clock {
  filter: invert(11%) sepia(75%) saturate(6294%) hue-rotate(1deg)
    brightness(83%) contrast(115%);
  margin-top: 25px;
  margin-bottom: 35px;
}
</style>
